import React, { useState } from "react";
import { t } from "i18next";
import requestResetPassword from "../services/requestResetPassword";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import Noty from "noty";

const RequestResetPassword = () => {  
  const logoName = process.env.REACT_APP_LOGO_NAME;
  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;
  const [emailOrUsername, setEmailOrUsername] = useState("");

  const onSuccess = (data: any) => {    
    new Noty({
      text: t(data.message),
      theme: "relax",
      layout: "topCenter",
      type: "success",
      timeout: 6000,
    }).show();
  };

  const errorHandler = useErrorHandler();

  const handleLogin = async (e: any) => {
    let requestResetPasswordData = {};
    if (emailOrUsername.includes('@')) {
      requestResetPasswordData = {
        email: emailOrUsername,
      }
    } else {
      requestResetPasswordData = {
        username: emailOrUsername,
      }
    }
    requestResetPassword(requestResetPasswordData, onSuccess, errorHandler);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin(event);
    }
  };

  return (
      <div style={{
        backgroundImage: "url(" + backgroundSrc + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
    <div
      className="d-flex flex-column align-items-center justify-content-center  auth-panel"
      style={{ minHeight: "100vh" }}
    >

      <div className="card">
        <div className="card-body">
          <div className="card-logo">
            <img
                src={logoName}
                alt="App Icon"
            />
          </div>
          <h5 className="card-title text-center mb-4">{t("reset_password")}</h5>
          <form>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                {t("email_or_user")}:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <button
              onClick={handleLogin}
              type="button"
              className="btn btn-primary w-100"
            >
              {t("reset_password")}
            </button>
          </form>
        </div>
      </div>
    </div>
      </div>
  );
};

export default RequestResetPassword;
