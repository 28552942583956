import {useContext, useEffect, useState} from "react";
import {t} from "i18next";
import {Card, Tab, Tabs} from "react-bootstrap";
import {useParams} from "react-router-dom";
import useDetailsAction from "../actions/details/useDetailsAction";
import {DetailsActionProvider as PageDetailsActionContext} from "@bloonde/cms/cms-pages/actions/details/DetailsActionContext";
import PageEditionByPageablePage from "@bloonde/cms/cms-pages/pages/PageEditionByPageablePage";
import {DetailsActionContext} from "@main/houses/actions/details/DetailsActionContext";
import HouseApiInfo from "@main/houses/components/HouseApiInfo";
import ReviewRouterWrapper from "@bloonde/reviews/reviews/wrappers/ReviewRouterWrapper";

function HouseDashboardPage() {
    let {houseId} = useParams();

    if (!houseId) {
        houseId = "0";
    }

    const {element} = useContext(DetailsActionContext);

    const {
        getFunction,
    } = useDetailsAction(parseInt(houseId));

    useEffect(() => {
        getFunction(houseId);
    }, [houseId]);

    const filters = {
        house_id: houseId,
        reviewable_id: houseId,
        reviewable_type: "App\\Models\\House",
    };
    const formFilterValues = {
        house_id: houseId,
        reviewable_id: houseId,
        reviewable_type: "App\\Models\\House",
    };
    const formDefaultValues = {
        house_id: houseId,
        reviewable_id: houseId,
        reviewable_type: "App\\Models\\House",
        reviewer_type: "App\\Models\\Customer",
        review_schema_id: 1, // Solo hay uno
    };

    const [contentTabLoaded, setContentTabLoaded] = useState(true);
    const [apiDataTabLoaded, setApiDataTabLoaded] = useState(false);
    const [reviewsTabLoaded, setReviewsTabLoaded] = useState(false);

    const onSelect = (key: string) => {
        if (key === "content") {
            setContentTabLoaded(true);
        } else if (key === "api_data") {
            setApiDataTabLoaded(true);
        } else if (key === "reviews") {
            setReviewsTabLoaded(true);
        }
    };

    return (
        <>
            <Tabs
                defaultActiveKey="content"
                id="uncontrolled-tab-example"
                className=""
                onSelect={(key: any) => {
                    onSelect(key);
                }}
            >
                <Tab eventKey="content" title={t("content")}>
                    {contentTabLoaded && (
                        <PageDetailsActionContext>
                            <PageEditionByPageablePage
                                pageableId={parseInt(houseId)}
                                pageableType="App\Models\House"
                            />
                        </PageDetailsActionContext>
                    )}
                </Tab>
                <Tab eventKey="api_data" title={t("api_data")}>
                    {apiDataTabLoaded && (
                        <div className="row">
                            <div className="mb-5">
                                <Card>
                                    <Card.Header
                                        className="d-flex align-items-center justify-content-between ps-3 bg-white">
                                        <span>{t("House")}</span>
                                    </Card.Header>

                                    <Card.Body>
                                        <HouseApiInfo pageableData={element}/>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    )}
                </Tab>
                <Tab eventKey="reviews" title={t("reviews")}>
                    <div className="row">
                        <div className="mb-5">
                            {reviewsTabLoaded && (
                                <ReviewRouterWrapper
                                    config={{showReviewSchemaSelector: true}}
                                    filters={filters}
                                    formDefaultValues={formDefaultValues}
                                    formFilterValues={formFilterValues}
                                />
                            )}
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
}

export default HouseDashboardPage;
