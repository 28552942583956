import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import update from "@beeldit/core/services/update";
import { DetailsActionContext } from "../details/DetailsActionContext";

function useUpdateSection() {

  const errorHandler = useErrorHandler();

  const {
    element: page,
    setBackendFormErrors: setBackendFormErrors,
    setSavingSection
  } = useContext(DetailsActionContext);

  return (pageId: any, sectionId: any, section: any, onSuccess: any) => {
    setSavingSection(sectionId);
    update(`pages/${pageId}/sections`, sectionId, section)
      .then((response: any) => {
        setSavingSection("");
        onSuccess(response.data);
        setBackendFormErrors({});
      })
      .catch((error: any) => {
        // errors.response.data contiene una variable errors que es un objeto donde la key debería ser sections.{index}.content.elements...
        // Sin embargo en errors viene un objeto con key que  es content.elements... por lo que tenemos que cambiar la key
        // Primero tenemos que saber el indice del section dentro del page
        setSavingSection("");
        let sectionIndex = page.sections.findIndex(
          (s: any) => s.id == sectionId
        );
        let newErrors: any = {};
        for (let key in error.response.data.errors) {
          let newKey = key.replace("content.elements", `sections.${sectionIndex}.content.elements`);
          newErrors[newKey] = error.response.data.errors[key];
        }
        error.response.data.errors = newErrors;
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useUpdateSection;
