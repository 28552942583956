import { getApi } from "@beeldit/core/helpers/api";

function requestResetPassword(resetPasswordData: any, onSuccess: any, onError: any) {
  const requestResetPassword = async (resetPasswordData: any) => {
    try {
      const response = await getApi().post("/send-reset-password", resetPasswordData);
      onSuccess(response.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
      onError(error);
    }
  };

  requestResetPassword(resetPasswordData);
}

export default requestResetPassword;
