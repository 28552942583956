import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      title: "Title",
      slug: "Slug",
      published: "Published",
      seo_title: "SEO Title",
      seo_description: "SEO Description",
      seo_image: "SEO Image",
      seo: "SEO",
      seo_image_filename: "Filename",
      seo_image_url: "URL",
      sign_in: "Sign in",
      email_or_user: "Email",
      email_or_user_placeholder: "Type your email ...",
      password: "Password",
      password_placeholder: "Type your password ...",
      forgot_password: "He olvidado mi contraseña",
      header: "Header",
      footer: "Footer",
      pages: "Pages",
      houses: "Houses",
      template: "Template",
      logo: "Logo",
      entries: "Entries",
      create_entry: "Create entry",
      label: "Label",
      actions: "Actions",
      main_data: "Main data",
      house: "House",
      name: "Name",
      reviews: "Reviews",
      create_review: "Create review",
      comment: "Comment",
      gallery: "Gallery",
      create_image: "Add image",
      logout: "Logout",
      configurations: "Configurations",
      articles: "Experiences",
      api_houses: "Api Houses",
      seo_data: "SEO data",
      img: "Image",
      remove: "Remove",
      label_es: "Spanish label",
      label_en: "English label",
      label_de: "German label",
      blank: "Abrir en nueva pestaña",
      parent_id: "Parent entry",
      type: "Type",
      internal_page: "Internal page",
      external_page: "External page",
      hierarchy: "Hierarchy",
      url: "URL",
      page_id: "Page",
      successfull_action: "Action completed successfully",
      content: "Content",
      api_data: "Api data",
      yes: "Yes",
      no: "No",
      es: "ES",
      en: "EN",
      de: "DE",
      image: "Image",
      remove_image: "Remove image",
      add_image: "Add image",
      min_price: "Min price",
      max_price: "Max price",
      registration_date: "Registration date",
      modified_on: "Modified on",
      features: "Features",
    },
  },
  es: {
    translation: {
      title: "Título",
      slug: "Slug",
      published: "Publicado",
      seo_title: "Título SEO",
      seo_description: "Descripción SEO",
      seo_image: "Imagen SEO",
      seo: "SEO",
      seo_image_filename: "Nombre de archivo",
      seo_image_url: "URL",
      sign_in: "Iniciar sesión",
      password_confirmed: "Confirmar contraseña",
      change_password: "Cambiar contraseña",
      password_changing: "Cambio de contraseña",
      email_or_user: "Email",
      email_or_user_placeholder: "Escribe tu email ...",
      password: "Contraseña",
      password_placeholder: "Escribe tu contraseña ...",
      reset_password: "Recuperar contraseña",
      invalid_credential: "Usuario y/o contraseña inválida",
      invalid_email: "Email o usuario incorrecto",
      sent_reset_password: "Se ha enviado un correo electrónico con las instrucciones para restablecer la contraseña.",
      forgot_password: "He olvidado mi contraseña",
      header: "Cabecera",
      footer: "Pie",
      pages: "Páginas",
      houses: "Casas",
      template: "Plantilla",
      logo: "Logo",
      entries: "Entradas",
      create_entry: "Crear entrada",
      label: "Etiqueta",
      actions: "Acciones",
      main_data: "Datos principales",
      house: "Casa",
      name: "Nombre",
      reviews: "Reseñas",
      create_review: "Crear reseña",
      comment: "Comentario",
      gallery: "Galería",
      create_image: "Añadir imagen",
      logout: "Cerrar sesión",
      configurations: "Configuraciones",
      articles: "Experiencias",
      api_houses: "Casas API",
      seo_data: "Datos SEO",
      img: "Imagen",
      remove: "Eliminar",
      label_es: "Etiqueta en español",
      label_en: "Etiqueta en inglés",
      label_de: "Etiqueta en alemán",
      blank: "Abrir en nueva pestaña",
      parent_id: "Entrada padre",
      type: "Tipo",
      internal_page: "Página interna",
      external_page: "Página externa",
      hierarchy: "Jerarquía",
      url: "URL",
      page_id: "Página",
      successfull_action: "Acción completada con éxito",
      content: "Contenido",
      api_data: "Datos API",
      yes: "Sí",
      no: "No",
      es: "ES",
      en: "EN",
      de: "DE",
      image: "Imagen",
      remove_image: "Eliminar imagen",
      add_image: "Añadir imagen",
      min_price: "Precio mínimo",
      max_price: "Precio máximo",
      registration_date: "Fecha de registro",
      modified_on: "Modificado el",
      features: "Características",
      "Unauthenticated.": "Sesión caducada. Por favor, inicia sesión de nuevo.",
      server_error:
        "Ha ocurrido un error en el servidor. Por favor, intenta de nuevo.",
      Footer: "Pie",
      save_changes: "Guardar cambios",
      "Drag 'n' drop one file here, or click to select file":
        "Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo",
      close: "Cerrar",
      remove_entry: "Eliminar entrada",
      "are_you_shure_you_want_to_delete_this_entry?":
        "¿Estás seguro de que quieres eliminar esta entrada?",
      Actions: "Acciones",
      select_an_page: "Selecciona una página",
      create_page: "Crear experiencia",
      review_schema_id: "Fuente",
      external_url: "URL externa",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
