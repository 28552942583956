import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { t } from "i18next";
import resetPassword from "../services/resetPassword";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";

let interceptorId: number | null = null;

interface Prop {
  redirectUrls?: any;
}

const ResetPassword = () => {  
  const logoName = process.env.REACT_APP_LOGO_NAME;

  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;
  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPasswordConfirmed] = useState("");
  const [backendErrors, setBackendErrors] = useState({} as any);
  
  let [ searchParams ] = useSearchParams();
  let token = searchParams.get('token');
  let email = searchParams.get('email');

  if (!token) {
    token = '';
  }
    

  useEffect(() => {
    
  }, []);

  const navigate = useNavigate();

  const onSuccess = (data: any) => {    
    navigate('/login');
  };

  const errorHandler = useErrorHandler();

  const handleResetPassword = async (e: any) => {
    resetPassword({ password: password, password_confirmation: passwordConfirmed, token: token, email: email }, onSuccess, errorHandler, setBackendErrors);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleResetPassword(event);
    }
  };

  return (
      <div style={{
        backgroundImage: "url(" + backgroundSrc + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
    <div
      className="d-flex flex-column align-items-center justify-content-center auth-panel"
      style={{ minHeight: "100vh" }}
    >

      <div className="card">
        <div className="card-body">
          <div className="card-logo">
            <img
                src={logoName}
                alt="App Icon"
            />
          </div>
          <h5 className="card-title text-center mb-4">{t("password_changing")}</h5>
          <form>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                {t("password")}:
              </label>
              <input
                type="password"
                className="form-control"
                id="username"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            {
              backendErrors.password && backendErrors.password.__errors.map((error: any) => (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              ))
            }
            <div className="mb-3">
              <label htmlFor="password_confirmed" className="form-label">
                {t("password_confirmed")}:
              </label>
              <input
                type="password"
                className="form-control"
                id="password_confirmed"
                value={passwordConfirmed}
                onChange={(e) => setPasswordConfirmed(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <button
              onClick={handleResetPassword}
              type="button"
              className="btn btn-primary w-100"
            >
              {t("change_password")}
            </button>
          </form>
        </div>
      </div>
    </div>
      </div>
  );
};

export default ResetPassword;
