import { ValidationData, ValidatorType } from "@rjsf/utils";

 const EmptyValidation: ValidatorType<any> = {
    validateFormData: (
      formData: any,
      schema: any,
      customValidate: any,
      transformErrors: any
    ): ValidationData<any> => {
      return {
        errors: [], // Devuelve un array vacío de errores
        errorSchema: {}, // Devuelve un esquema de errores vacío
      };
    },
    isValid: (formData: any, schema: any) => true, // Siempre devuelve true para indicar que es válido
    
    // Función que transforma el esquema de validación en una lista de errores.
    toErrorList: (errorSchema: any) => {
      return []; // No hay errores en la lista
    },
    
    // Implementación vacía de rawValidation (puede ser la validación cruda que use RJSF internamente)
    rawValidation: (formData: any, schema: any) => {
      return {
        errors: [],
        errorSchema: {},
        valid: true, // Indica que la validación es válida
      };
    },
  };

  export default EmptyValidation;
  