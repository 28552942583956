import { XCircle, ArrowUp, ArrowDown } from "@phosphor-icons/react/dist/ssr";
import { ArrayFieldTemplateProps } from "@rjsf/utils";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";

function DefaultArrayTemplate(
  props: ArrayFieldTemplateProps,
  className: string,
  id: string
) {
  const { actionTemplate } = useUpdateSectionAction();

  return (
    <div className={className}>
      {id !== "root_sections" ? (
        <>
          <h3>
            {props.title} {actionTemplate}
          </h3>
        </>
      ) : null}
      <div className="my-2">
        <div className="row">
          {props.items.map((element, index) => {
            const colClass = element.uiSchema?.colClass || "col-6";
            return (
              <>
                {element.schema.properties && (
                  <div className={colClass} key={element.key}>
                    {id !== "root_sections" ? (
                      <div className="mb-3 d-flex align-items-center">
                        <h4 className="element-title">
                          {props.title} {index + 1}{" "}
                        </h4>
                        <div className="ms-2 d-flex align-items-center">
                          {/* Icono para eliminar */}
                          {props.canAdd && (
                            <XCircle
                              size={30}
                              className="pe-hand text-danger"
                              onClick={element.onDropIndexClick(index)}
                            />
                          )}

                          {/* Iconos de ordenación */}
                          {element.hasMoveUp && (
                            <ArrowUp
                              size={30}
                              className="pe-hand ms-2 text-primary"
                              onClick={element.onReorderClick(index, index - 1)}
                            />
                          )}
                          {element.hasMoveDown && (
                            <ArrowDown
                              size={30}
                              className="pe-hand ms-2 text-primary"
                              onClick={element.onReorderClick(index, index + 1)}
                            />
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div className="flex-grow-1">{element.children}</div>
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className="mt-2 d-flex justify-content-end">
          <div>
            {props.canAdd && (
              <button
                className="btn btn-primary ml-auto"
                onClick={props.onAddClick}
              >
                +
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultArrayTemplate;
