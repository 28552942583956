import { getApi } from "@beeldit/core/helpers/api";

function resetPassword(resetPasswordData: any, onSuccess: any, onError: any, backendErrors: any) {
  const resetPassword = async (resetPasswordData: any) => {
    try {
      const response = await getApi().post("/reset-password", resetPasswordData);
      onSuccess(response.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
      onError(error, backendErrors);
    }
  };

  resetPassword(resetPasswordData);
}

export default resetPassword;
